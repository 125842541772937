import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import Fade from "react-reveal/Fade"
import Breadcrumbs from "src/utils/Breadcrumbs"
import WoodsAndFinishes from "src/components/WoodsAndFinishes"
import BlockCta from "src/components/Blocks/BlockCta"
import BlockConfigurations from "src/components/Blocks/BlockConfigurations"

const GarageDoorsWoodsAndFinishes = ({
  data: { allPrismicWoodsAndFinishes },
}) => {
  const data = allPrismicWoodsAndFinishes

  const crumbs = [
    { label: "Garage Doors", href: "/garage-doors" },
    {
      label: "Woods & Colours",
      href: "/garage-doors/woods-finishes",
      active: true,
    },
  ]

  return (
    <main>
      <Seo title={"Garage Door Colours and Finishes | Woods and Materials"} 
        description={"Explore our range of garage door colours, finishes, woods and glazing options. Find the perfect look to compliment your style with Deuren."}
        />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <WoodsAndFinishes {...data} type="garage" />
      <section className={`bg-offwhite`}>
        <div className={`container`}>
          <div className={`flex flex-col space-y-12 lg:space-y-0 lg:flex-row`}>
            <div className="w-full lg:w-7/12">
              <div className="grid grid-cols-2 gap-4">
                <Fade>
                  <video autoPlay loop muted playsInline>
                    <source
                      src={`https://d2jpqhjplyyyp0.cloudfront.net/Deuren-Garage-Door.mp4`}
                      type="video/mp4"
                    />
                  </video>
                  <img
                    src={`https://images.prismic.io/deuren/f91b7384-1d95-4e1e-8f11-eb23cf99ab2d_Woods-%26-finishes-for-any-door-style-1+%281%29.jpg?auto=compress,format`}
                    alt={`Woods and finishes for any door style`}
                  />
                </Fade>
              </div>
            </div>
            <div className={`w-full lg:w-5/12 lg:pl-20`}>
              <div className="sticky top-32 text-center lg:text-left">
                <Fade distance="20px" bottom>
                  <h1 className="font-display text-gold rfs:text-6xl">
                    Woods &amp; finishes for any door style
                  </h1>
                  <div className="w-12 mt-5 separator"></div>
                  <div className="mt-5">
                    <p>
                      Our woods and finishes are the perfect solution to
                      bringing an updated look and feel to a home. See our
                      contemporary or traditional door styles that will suit
                      your style and let your creativity run wild.
                    </p>
                  </div>
                  <Link
                    className="btn mt-6"
                    to={`/garage-doors/styles/`}
                    role="button"
                  >
                    See all garage door styles
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlockConfigurations props={`garage-doors`} />
      <BlockCta />
    </main>
  )
}

export default GarageDoorsWoodsAndFinishes

export const GarageDoorsWoodsAndFinishesQuery = graphql`
  query GarageDoorsWoodsAndFinishesQuery {
    allPrismicWoodsAndFinishes(
      filter: { data: { door_type: { eq: "Garage Door" } } }
    ) {
      edges {
        node {
          data {
            colour_type
            title {
              text
            }
            featured_image {
              url(imgixParams: { auto: "format" })
              alt
            }
            door_type
            description {
              richText
            }
            related_page {
              uid
            }
          }
        }
      }
    }
  }
`
